export const dictionary = {
    'tg-TJ': {
        translation: {
            'Все выпуски': 'Ҳамаи маҷаллаҳо',
            'Всё о спорте': 'Ҳама дар бораи варзиш',
            'Всё о авто': 'Ҳама дар бораи мошинҳо',
            'Всё о питании': 'Ҳама дар бораи ғизо',
            'Всё о гаджетах': 'Ҳама дар бораи гаҷетҳо',
            'Всё о стиле': 'Ҳама дар бораи услубҳо',
            'Всё о космосе': 'Ҳама дар бораи коинот',
            'Всё об отдыхе': 'Ҳама дар бораи истироҳат',
            'На главную': 'Ба саҳифаи асосӣ',
            'Ещё по теме': 'Бештар оиди мавзӯъ',
            'Чтиво': 'Мутоила кардан',
            'Читать далее': 'Минбаъд хонед',
            'Смотреть видео': 'Видеоро тамошо кунед',
            'Видео': 'Видео',
            'Условия предоставления контент-услуги': 'Шартҳои пешниҳоди хидматҳои мундариҷа',
            'Правила подписки': 'Қоидахои обуна',
            'Загрузка страницы...': 'Пурборкунии саҳифа...',
            'Журнал загружается. Пожалуйста, подождите!': 'Маҷалла пурбор карда мешавад. Илтимос, интизор шавед.',
            'Пожалуйста, подождите!': 'Илтимос, интизор шавед.',
            'Что-то': 'Чизе',
            'пошло не так': 'нодуруст шуд',
            'Следующая статья': 'Мақолаи навбатӣ',
            'Назад в раздел': 'Бозгашт ба бахш',
            'Назад': 'Бозгашт  ',
            'Страница': 'Саҳифа',
            'из': 'аз',
            'Тег video не поддерживается вашим браузером': 'Теги видеоро браузери шумо дастгирӣ намекунад',
            'Пресса': 'Матбуот',
            'Про спорт': 'Варзиш',
            'Про автомобили': 'Мошинхо',
            'Про стиль': 'Услуб',
            'Про гаджеты': 'Гаҷетҳо',
            'Про отдых': 'Истирохат',
            'Про космос': 'Коинот',
            'Про питание': 'Ғизо',
            'Музыка': 'Мусиқӣ',
            'Следующий трек': 'Таронаи навбатӣ',
            'ЛК': 'кабинети шахсӣ',
            'Ссылка': 'Истинод',
        },
    },
    'en-GB': {
        translation: {

            'Все журналы': 'All magazines',
            'Всё о спорте': 'All about sports',
            'Всё о авто': 'All about cars',
            'Всё о питании': 'All about nutrition',
            'Всё о гаджетах': 'All about gadgets',
            'Всё о стиле': 'All about style',
            'Всё о космосе': 'Everything about space',
            'Всё об отдыхе': 'All about rest',
            'На главную': 'Home page',
            'Ещё по теме': 'More about this',
            'Чтиво': 'Reading',
            'Читать далее': 'Read more',
            'Смотреть видео': 'Watch the video',
            'Видео': 'Video',
            'Условия предоставления контент-услуги': 'Terms and Conditions of the content service provision',
            'Правила подписки': 'Terms of subscription',
            'Загрузка страницы...': 'Page is loading...',
            'Журнал загружается. Пожалуйста, подождите!': 'Magazine is downloading. Please wait!',
            'Пожалуйста, подождите!': 'Please wait!',
            'Что-то': 'Something',
            'пошло не так': 'went wrong',
            'Следующая статья': 'Next article',
            'Назад в раздел': 'Back to section',
            'Назад': 'Back',
            'Страница': 'Page',
            'из': 'from',
            'Тег video не поддерживается вашим браузером': 'Your browser is not supporting video format\n',
            'Пресса': 'Magazines',
            'Про спорт': 'Sport',
            'Про автомобили': 'Auto',
            'Про стиль': 'Style',
            'Про гаджеты': 'Gadgets',
            'Про отдых': 'Relaxation',
            'Про космос': 'Space',
            'Про питание': 'Food',
            'Музыка': 'Music',
            'Следующий трек': 'Next track',
            'ЛК': 'Manage the service in',
            'Ссылка': 'Personal Area',
        },
    },
    'ru-RU': {
        translation: {
            'Пресса': 'Пресса',
            'Про спорт': 'Спорт',
            'Про автомобили': 'Авто',
            'Про стиль': 'Стиль',
            'Про гаджеты': 'Гаджеты',
            'Про отдых': 'Отдых',
            'Про космос': 'Космос',
            'Про питание': 'Питание',
            'ЛК': 'Управление услугой в',
            'Ссылка': 'Личном кабинете',
        },
    },
};
